import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { adminPost, adminUpload } from '../../utils';
import { URLS } from '../../constants';
import getFormattedDate from './utils';
import SmallSpinner from '../../components/SmallSpinner';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

const localizer = momentLocalizer(moment);

const ActiveClientsDetails = () => {
  const { userid } = useParams();
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedSiteIdContract, setSelectedSiteIdContract] = useState(null);
  const [selectedSiteIdAppointment, setSelectedSiteIdAppointment] =
    useState(null);
  const [willSkipContract, setWillSkipContract] = useState(false);
  const [willSkipAppointment, setWillSkipAppointment] = useState(false);
  const [canSkipContract, setCanSkipContract] = useState('loading');
  const [canSkipAppointment, setCanSkipAppointment] = useState('loading');
  const [saving, setSaving] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [scheduling, setScheduling] = useState(false);
  const [showAddSiteModal, setShowAddSiteModal] = useState(false);
  const [newSiteData, setNewSiteData] = useState({
    address: '',
    dailyPatrons: '',
    squareFootage: '',
  });
  const [addingSite, setAddingSite] = useState(false);
  const [inviteLink, setInviteLink] = useState('');

  useEffect(() => {
    const fetchOnboardingSettings = async () => {
      const response = await adminPost(URLS.GET_ACTIVE_CLIENT_DETAILS, {
        userid,
      });
      if (response.ok) {
        const data = await response.json();
        setClientDetails(data);
        const defaultSite = data.sites.find((site) => site.default);
        setCanSkipContract(defaultSite?.hostingAgreements.length > 0);
        setCanSkipAppointment(true);
        setWillSkipContract(data.willSkipContract);
        setWillSkipAppointment(data.willSkipAppointment);
      } else {
        console.error('Failed to fetch onboarding settings');
      }
      setLoadingDetails(false);
    };
    fetchOnboardingSettings();
  }, [userid]);

  const handleSaveWillSkip = async () => {
    setSaving(true);
    try {
      const response = await adminPost(URLS.SAVE_ONBOARDING_SETTINGS, {
        userid,
        willSkipContract,
        willSkipAppointment,
      });

      if (!response.ok) {
        throw new Error('Failed to save settings');
      }
      window.location.reload();
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleInvite = async () => {
    setInviting(true);
    const response = await adminPost(URLS.INVITE_CLIENT_TO_MAKE_ACCOUNT, {
      userid,
    });
    if (!response.ok) {
      console.error('Failed to invite client');
      return;
    }
    const data = await response.json();
    setInviteLink(data.link);
    setInviting(false);
  };

  const handleFileChange = (e, siteId) => {
    setFile(e.target.files[0]);
    setSelectedSiteIdContract(siteId);
  };

  const handleFileUpload = async (e, siteId) => {
    e.preventDefault();
    if (!file || selectedSiteIdContract !== siteId) return;
    setUploading(true);

    const result = await adminUpload(URLS.UPLOAD_CONTRACT, {
      file,
      userid,
      siteId,
    });

    if (!result.success) {
      console.error(result.message);
      setUploading(false);
      return;
    }

    window.location.reload();
    setUploading(false);
  };

  const handleScheduleAppointment = async (siteId) => {
    if (!selectedAppointment || selectedSiteIdAppointment !== siteId) {
      return;
    }
    setScheduling(true);
    try {
      const response = await adminPost(URLS.SCHEDULE_APPOINTMENT, {
        userid,
        siteId,
        appointment: selectedAppointment.start,
      });

      if (!response.ok) {
        throw new Error('Failed to save settings');
      }
      window.location.reload();
    } catch (error) {
      console.error('Error scheduling appointment:', error);
    } finally {
      setScheduling(false);
    }
  };

  const handleSelectEvent = (event, siteId) => {
    // Ensure this event is not 'Busy' and can initiate a 3-hour block
    if (event.title !== 'Busy' && checkAvailability(event.start)) {
      setSelectedAppointment({
        start: event.start,
        end: moment(event.start).add(3, 'hours').toDate(),
      });
      setSelectedSiteIdAppointment(siteId);
    } else {
      setSelectedAppointment(null);
    }
  };

  const checkAvailability = (start) => {
    for (let i = 0; i < 6; i++) {
      // Check for 3 hours of availability
      const currentSlotStart = moment(start).add(i * 30, 'minutes');
      if (!isSelectable(currentSlotStart)) return false;
    }
    return true;
  };

  const formatEvents = (availability) => {
    return availability
      .filter((event) => moment(event.start).isAfter(moment())) // Filter out past events
      .map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        title: event.title,
      }));
  };

  const eventStyleGetter = (event) => {
    const eventStart = moment(event.start);
    const eventEnd = moment(event.end);
    let backgroundColor = '#d3d3d3'; // Default to grey for unselectable

    if (event.title === 'Busy') {
      backgroundColor = '#ff6347'; // Red for busy
    } else if (
      selectedAppointment &&
      eventStart.isSameOrAfter(selectedAppointment.start) &&
      eventEnd.isSameOrBefore(moment(selectedAppointment.start).add(3, 'hours'))
    ) {
      backgroundColor = '#32cd32'; // Green for selected valid range
    } else if (canInitiateValidBlock(event.start)) {
      backgroundColor = '#3174ad'; // Blue for selectable
    }
    return {
      style: {
        backgroundColor,
        color: 'white',
      },
    };
  };

  const canInitiateValidBlock = (start) => {
    // Check availability for the next 3 hours from 'start'
    for (let i = 0; i < 6; i++) {
      const currentSlotStart = moment(start).add(i * 30, 'minutes');
      if (!isSelectable(currentSlotStart)) {
        return false;
      }
    }
    return true;
  };

  const isSelectable = (start) => {
    const slotEnd = moment(start).add(30, 'minutes');
    const slotEvent = clientDetails.sites[0].installer?.availability.find(
      (e) =>
        moment(e.start).isSame(start) &&
        moment(e.end).isSame(slotEnd) &&
        e.title !== 'Busy',
    );
    return !!slotEvent;
  };

  const openAddSiteModal = () => {
    setShowAddSiteModal(true);
  };

  const closeAddSiteModal = () => {
    setShowAddSiteModal(false);
    setNewSiteData({
      address: '',
      dailyPatrons: '',
      squareFootage: '',
    });
  };

  const handleNewSiteChange = (e) => {
    const { name, value } = e.target;
    setNewSiteData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddSiteSubmit = async () => {
    setAddingSite(true);
    const siteDetails = {
      ...newSiteData,
      userid,
    };

    const response = await adminPost(URLS.ADD_SITE, siteDetails);
    if (response.ok) {
      window.location.reload();
    } else {
      console.error('Failed to add site');
      setAddingSite(false);
    }
    closeAddSiteModal();
  };

  return (
    <div className="admin-dash-container">
      <Link to="/admin/active-clients" className="custom-form-1-back-link">
        Back To Active Clients
      </Link>
      <h2>Active Client Details</h2>
      <hr />
      {loadingDetails ? (
        <SmallSpinner />
      ) : (
        <>
          <h3>Account Details</h3>
          <p>Business Name: {clientDetails?.businessName}</p>
          <p>Email: {clientDetails?.email}</p>
          <p>Full Name: {clientDetails?.fullName}</p>
          <p>Phone: {clientDetails?.phoneNumber}</p>
          <p>Sales Rep: {clientDetails?.salesRep}</p>
          <p>Ops Rep: {clientDetails?.opsRep}</p>
          <hr />
          <h3>Onboarding Settings</h3>
          <div>
            <h4>Skip Onboarding Steps</h4>
            <label>
              <input
                type="checkbox"
                checked={willSkipContract}
                onChange={(e) => setWillSkipContract(e.target.checked)}
                disabled={canSkipContract === 'loading' || !canSkipContract}
              />
              Skip Contract
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={willSkipAppointment}
                onChange={(e) => setWillSkipAppointment(e.target.checked)}
                disabled={
                  canSkipAppointment === 'loading' || !canSkipAppointment
                }
              />
              Skip Appointment
            </label>
          </div>
          <button
            onClick={handleSaveWillSkip}
            disabled={
              saving ||
              canSkipContract === 'loading' ||
              canSkipAppointment === 'loading'
            }
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
          <h4>Invite Client to Start Onboarding</h4>
          <button
            onClick={handleInvite}
            disabled={
              inviting ||
              canSkipContract === 'loading' ||
              canSkipAppointment === 'loading'
            }
          >
            {inviting ? 'Inviting...' : 'Invite'}
          </button>
          <br />
          <br />
          Link : {inviteLink}
          <hr />
          <h3>Sites</h3>
          <button onClick={openAddSiteModal}>Add Site</button>
          {showAddSiteModal && (
            <div className="add-site-modal">
              <div className="add-site-modal-content">
                <span className="close" onClick={closeAddSiteModal}>
                  &times;
                </span>
                <h4>Add New Site</h4>
                <label>
                  Address:
                  <input
                    type="text"
                    name="address"
                    value={newSiteData.address}
                    onChange={handleNewSiteChange}
                  />
                </label>
                <label>
                  Daily Patrons:
                  <input
                    type="text"
                    name="dailyPatrons"
                    value={newSiteData.dailyPatrons}
                    onChange={handleNewSiteChange}
                  />
                </label>
                <label>
                  Square Footage:
                  <input
                    type="text"
                    name="squareFootage"
                    value={newSiteData.squareFootage}
                    onChange={handleNewSiteChange}
                  />
                </label>
                {addingSite ? (
                  <SmallSpinner />
                ) : (
                  <button
                    onClick={handleAddSiteSubmit}
                    disabled={
                      newSiteData.squareFootage.length === 0 ||
                      newSiteData.dailyPatrons.length === 0 ||
                      newSiteData.address.length === 0
                    }
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          )}
          {clientDetails?.sites.map((site) => (
            <div key={site.id} className="site-details">
              <h4>Site Details</h4>
              Site Address: {site.address}
              <br />
              Status: {site.status}
              <br />
              Installer:{' '}
              {!site.installer.email.length
                ? 'Waiting for OPS to assign.'
                : site.installer.email}
              <br />
              Daily Patrons: {site.dailyPatrons} <br />
              Square Footage: {site.squareFootage}
              <br /> Site used during onboarding: {String(site.default)}
              <hr />
              <h4>Hosting Agreements</h4>
              <ul>
                {site.hostingAgreements.map((agreement) => (
                  <div className="active-client-details-box" key={agreement.id}>
                    Status: {agreement.status}
                    <br />
                    Url:{' '}
                    <a
                      href={agreement.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Document
                    </a>
                    <br />
                    Created: {getFormattedDate(agreement.created)}
                  </div>
                ))}
              </ul>
              {!site.hostingAgreements.length && 'No hosting agreements yet.'}
              <hr />
              <h4>Appointments</h4>
              <ul>
                {site.appointments.map((appointment) => (
                  <div
                    className="active-client-details-box"
                    key={appointment.id}
                  >
                    Scheduled Date:{' '}
                    {getFormattedDate(appointment.scheduledDate)}
                  </div>
                ))}
              </ul>
              {!site.appointments.length && 'No appointments yet.'}
              <hr />
              <h4>Upload Contract</h4>
              <form onSubmit={(e) => handleFileUpload(e, site.id)}>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, site.id)}
                  accept="application/pdf"
                />
                <button
                  type="submit"
                  disabled={
                    uploading || !file || selectedSiteIdContract !== site.id
                  }
                >
                  {uploading ? 'Uploading...' : 'Upload'}
                </button>
              </form>
              <hr />
              {site.installer.email.length !== 0 && (
                <>
                  <h4>Create Appointment with {site.installer?.email}</h4>
                  {selectedAppointment && (
                    <div>
                      <p>
                        Selected Time:{' '}
                        {moment(selectedAppointment.start).format('LLLL')} -{' '}
                        {moment(selectedAppointment.end).format('LLLL')}
                      </p>
                      <button
                        disabled={scheduling}
                        onClick={() => handleScheduleAppointment(site.id)}
                      >
                        Schedule
                      </button>
                      <br />
                      <hr />
                    </div>
                  )}
                  <Calendar
                    localizer={localizer}
                    defaultView="week"
                    views={['week', 'day']}
                    events={formatEvents(site.installer?.availability)}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    eventPropGetter={eventStyleGetter}
                    onSelectEvent={(e) => handleSelectEvent(e, site.id)}
                    selectable
                    onSelectSlot={() => {}}
                  />
                </>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ActiveClientsDetails;
