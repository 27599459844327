import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { adminPost } from '../../utils';
import { ROLE, URLS } from '../../constants';
import './Admin.css';
import Spinner from '../../components/Spinner';

const PotentialBusinessClientsAdd = () => {
  const [formData, setFormData] = useState({
    businessName: '',
    businessTitle: '',
    fullName: '',
    businessEmail: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    dailyPatrons: '',
    squareFootage: '',
    referredBy: '',
    additionalNotes: '',
    readyForReview: false,
    skipOpsReview: false,
    opsUser: '',
    installer: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [allInstallers, setAllInstallers] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      setSubmitting(true);
      const response = await adminPost(URLS.ADD_POTENTIAL_BUSINESS, formData);
      if (!response.ok) {
        const body = await response.json();
        console.error(`Error adding potential business: ${body.error}`);
        setErrorMessage(`Error adding potential business: ${body.error}`);
        setSubmitting(false);
        return;
      }
      setSuccessMessage('Potential client succesfully added.');
    } catch (error) {
      setErrorMessage('Error adding potential business');
      console.error('Error adding potential business:', error);
    }
    setSubmitting(false);

    // Reset form fields
    setFormData({
      businessName: '',
      businessTitle: '',
      fullName: '',
      businessEmail: '',
      phoneNumber: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      dailyPatrons: '',
      squareFootage: '',
      referredBy: '',
      additionalNotes: '',
      readyForReview: false,
      skipOpsReview: false,
      opsUser: '',
      installer: '',
    });
  };

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await adminPost(URLS.GET_INSTALLERS);
        if (!response.ok) {
          console.error('Failed to fetch installers');
          return;
        }
        const data = await response.json();
        setAllInstallers(data.installers);
      } catch (error) {
        console.error('Error fetching installers:', error);
      }
    };
    fetchInstallers();
  }, []);

  return (
    <div className="custom-form-1-admin-dash-container">
      <Link
        to="/admin/potential-business-clients"
        className="custom-form-1-back-link"
      >
        Back To Potential Business Clients
      </Link>
      <h2>Add Potential Business Client</h2>
      <form className="custom-form-1-comment-form" onSubmit={handleSubmit}>
        <div className="custom-form-1-form-group">
          <label htmlFor="businessName" className="custom-form-1-label">
            Business Name:
          </label>
          <input
            type="text"
            id="businessName"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter business name"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="businessTitle" className="custom-form-1-label">
            Business Title:
          </label>
          <input
            type="text"
            id="businessTitle"
            name="businessTitle"
            value={formData.businessTitle}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter business title (CEO, CTO, Owner...)"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="fullName" className="custom-form-1-label">
            Full Name:
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter full name"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="businessEmail" className="custom-form-1-label">
            Email:
          </label>
          <input
            type="email"
            id="businessEmail"
            name="businessEmail"
            value={formData.businessEmail}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter email address"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="phoneNumber" className="custom-form-1-label">
            Phone Number:
          </label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter phone number"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="address" className="custom-form-1-label">
            Street Address:
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter street address"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="city" className="custom-form-1-label">
            City:
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter city"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="state" className="custom-form-1-label">
            State:
          </label>
          <input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter state"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="zipCode" className="custom-form-1-label">
            Zip Code:
          </label>
          <input
            type="text"
            id="zipCode"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter zip code"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="dailyPatrons" className="custom-form-1-label">
            Daily Patrons:
          </label>
          <input
            type="text"
            id="dailyPatrons"
            name="dailyPatrons"
            value={formData.dailyPatrons}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter daily patrons"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="squareFootage" className="custom-form-1-label">
            Square Footage:
          </label>
          <input
            type="text"
            id="squareFootage"
            name="squareFootage"
            value={formData.squareFootage}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="Enter square footage"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="referredBy" className="custom-form-1-label">
            Referred By (Optional):
          </label>
          <input
            type="text"
            id="referredBy"
            name="referredBy"
            value={formData.referredBy}
            onChange={handleChange}
            className="custom-form-1-input"
            placeholder="(Optional) Enter who referred the client"
          />
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="additionalNotes" className="custom-form-1-label">
            Sales Notes (Optional):
          </label>
          <textarea
            id="additionalNotes"
            name="additionalNotes"
            value={formData.additionalNotes}
            onChange={handleChange}
            className="custom-form-1-textarea"
            placeholder="(Optional) Enter any additional notes for the Ops Team"
          ></textarea>
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="readyForReview" className="custom-form-1-label">
            Ready for Review by Ops Team (Optional):
          </label>
          <input
            type="checkbox"
            id="readyForReview"
            name="readyForReview"
            checked={formData.readyForReview}
            onChange={handleChange}
            className="custom-form-1-checkbox"
          />
          <label
            htmlFor="readyForReview"
            className="custom-form-1-checkbox-label"
          >
            Ready for Review
          </label>
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="skipOpsReview" className="custom-form-1-label">
            Skip Review by Ops Team (Optional):
            <br />
            <i>Note: Must select Ops User and Installer</i>
          </label>
          <input
            type="checkbox"
            id="skipOpsReview"
            name="skipOpsReview"
            checked={formData.skipOpsReview}
            onChange={handleChange}
            className="custom-form-1-checkbox"
            disabled={!formData.opsUser || !formData.installer}
          />
          <label
            htmlFor="skipOpsReview"
            className="custom-form-1-checkbox-label"
          >
            Skip Ops Review
          </label>
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="opsUser" className="custom-form-1-label">
            Ops User:
          </label>
          <select
            name="opsUser"
            onChange={handleChange}
            value={formData.opsUser}
          >
            <option value="">Select Ops User</option>
            {allInstallers
              .filter((installer) => {
                return installer.role === ROLE.OPS;
              })
              .map((installer) => (
                <option key={installer.email} value={installer.email}>
                  {installer.email}
                </option>
              ))}
          </select>
        </div>
        <div className="custom-form-1-form-group">
          <label htmlFor="installer" className="custom-form-1-label">
            Installer:
          </label>
          <select
            name="installer"
            onChange={handleChange}
            value={formData.installer}
          >
            <option value="">Select Installer</option>
            {allInstallers.map((installer) => (
              <option key={installer.email} value={installer.email}>
                {installer.email}
              </option>
            ))}
          </select>
        </div>
        {submitting ? (
          <Spinner />
        ) : (
          <button
            type="submit"
            className="custom-form-1-button"
            disabled={
              !formData.fullName ||
              !formData.businessEmail ||
              !formData.phoneNumber ||
              !formData.address ||
              !formData.city ||
              !formData.state ||
              !formData.zipCode ||
              !formData.businessName ||
              !formData.businessTitle ||
              !formData.squareFootage ||
              !formData.dailyPatrons ||
              (formData.skipOpsReview &&
                (!formData.opsUser || !formData.installer)) ||
              (formData.skipOpsReview && formData.readyForReview)
            }
          >
            Submit
          </button>
        )}
        {errorMessage && (
          <p className="custom-form-1-error-message">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="custom-form-1-success-message">{successMessage}</p>
        )}
      </form>
    </div>
  );
};

export default PotentialBusinessClientsAdd;
