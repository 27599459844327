import { useState } from 'react';
import { Link } from 'react-router-dom';
import { post } from '../../utils';
import { URLS } from '../../constants';
import SmallSpinner from '../../components/SmallSpinner';

const UserForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const response = await post(URLS.USER_FORGOT_PASSWORD, { email });
      if (errorMessage) {
        setErrorMessage('');
      }

      if (response.ok) {
        setMessage(
          'Your password reset link has been emailed to you. You have 15 minutes to change your password.',
        );
        setSubmitting(false);
        setSubmitted(true);
      } else {
        setErrorMessage(
          'Failed to send password reset link. Please try again.',
        );
        setSubmitting(false);
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
      setSubmitting(false);
    }
  };

  return (
    <div className="admin-signin-container">
      <form className="admin-invite-form" onSubmit={handleSubmit}>
        <h2>Forgot Password</h2>
        {message ? (
          <p>{message}</p>
        ) : (
          <>
            <div>
              <label htmlFor="email">Enter email:</label>
              <input
                placeholder="Email"
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              {submitting ? (
                <SmallSpinner />
              ) : (
                <button
                  type="submit"
                  style={{ marginTop: '20px', padding: '10px 20px' }}
                  disabled={submitted || !email}
                >
                  Submit
                </button>
              )}
              {errorMessage && (
                <p style={{ marginTop: '20px' }}>{errorMessage}</p>
              )}
            </div>
          </>
        )}

        <Link to="/">Back to home.</Link>
      </form>
    </div>
  );
};

export default UserForgotPassword;
